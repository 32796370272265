<template>
	<div>
		<van-nav-bar
			title="歷史記錄"
			left-arrow
			fixed
			:z-index='111'
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="history">
			<!-- <h2 v-if="hasData" class="name qjc-texta-l">{{ user.name }} {{ user.sex == 1?'先生':(user.sex == 2?'女士':'') }}，你好</h2> -->
			<van-list 
				v-if="hasData"
				@load="getList" 
				:offset="0" 
				class="list"
				v-model="loading"
				:finished="finished"
				:immediate-check="false"
			>
				<div slot="default" v-for="(item, index) in lists" :key="item.id" class="qjc-bg-fff qjc-relative">
					<div class="qjc-clearfix">
						<div class="day qjc-fl qjc-texta-l">
							<p>{{ item.w }}</p>
							<p>{{ item.t }}</p>
						</div>
						<dl :class="{ 'last': index == lists.length-1,'only': lists.length == 1 }" class="month qjc-fl qjc-bg-primary qjc-c-fff">
							<dd>{{ item.m }}</dd>
							<dt>{{ item.d }}</dt>
						</dl>
						<div style="max-width: 70%" class="qjc-c-dark qjc-fts-32 qjc-ftw-b qjc-fl qjc-texta-l">
							{{ item.name }}
							<!-- <div v-if="item.is_finish == 1 || item.is_finish == 21" class="appoint qjc-fts-28">
								已預約線下體檢
								<p class="qjc-fts-24">預約的時間：{{ item.appoint.atime ? item.appoint.atime : '' }}</p>
								<p class="qjc-fts-24">預約的地點：{{ item.appoint.name ? item.appoint.name : '' }}</p>
							</div> -->
							<div v-if="item.is_finish == 11" class="wait">
								<p class="qjc-fts-24">請等待您的生化檢查結果</p>
							</div>
						</div>
						<!-- 已过期 -->
						<img v-if="item.is_finish == 90" style="position: absolute;top: 0.24rem;right: 0.24rem;width: 1.3rem;height: 0.8rem;" src="../../assets/images/stale.png" alt="">
						<!-- 已完成 -->
						<div v-if="item.is_finish == 80" v-show="item.type != 'M22'" @click="handleToDetail(item.goods_id, item.id, item.type)" class="check qjc-fr">
							查看報告
							<van-icon
								name='arrow'
								color="#6681FA"
								size="0.16rem"
							/>
						</div>
						<div v-if="item.is_finish != 80" class="check qjc-fr" style="color: #777F8F;">
							待完成
							<!-- <van-icon
								name='arrow'
								color="#6681FA"
								size="0.16rem"
							/> -->
						</div>
						<div v-if="item.is_finish == 10 || item.is_finish == 12 || item.is_finish == 20 || item.is_finish == 22" @click="$router.push('/newgoods/question')" class="to-ques qjc-fr qjc-c-primary">
							前往問卷填寫
							<van-icon
								name='arrow'
								color="#6681FA"
								size="0.16rem"
							/>
						</div>
					</div>
				</div>
			</van-list>
			<div v-else class="no-data qjc-fts-32">
				<van-image
					width="5.04rem"
					height="2.9rem"
					:src="require('@/assets/images/history_null.png')"
				/>
				<p class="qjc-fts-28">您還沒有體檢報告</p>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Icon, List, Image, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Icon)
	   .use(List)
	   .use(Image)
	   .use(Toast);
	   
	import { pagingData } from '@/js/axios.js'
	   
	export default{
		name: 'userHistory',
		data (){
			return {
				page: 0,//当前页，默认第0页
				pagesize: 10,//每页多少条数据 默认10
				lists: [],//歷史列表數據
				loading: false,//是否處於加載狀態
				finished: false,//是否加載完成
				user: JSON.parse(localStorage.getItem('user')) || ''
			}
		},
		computed: {
			hasData (){
				return this.lists.length>0?true:false;
			}
		},
		mounted() {
			this.getList();
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			getList (){
				this.loading = true;
				pagingData({
					access_token: localStorage.getItem('access_token'),
					page: ++this.page,
					pagesize: this.pagesize
				}).then(res => {
					if(res.code == 200){
						this.loading = false;
						let result = JSON.parse(window.atob(res.data))
						this.lists = this.lists.concat(result.data);
						console.log(result.data);
						//所有數據已加載完成
						if(result.pager.pagecount == this.page){
							this.finished = true;
						}
					}else{
						Toast.fail(res.msg);
					}
				})
			},
			handleToDetail(goodsId, id, type) {
				console.log(goodsId, id, type);
				if(type == "M22"){
					//暂无M22结果页
				}else if(type == 'ZHJK2'){
					this.$router.push({path: '/aizh/result/' + id})
				}else{
					console.log("goodsId");
					if(goodsId == 6) {
						this.$router.push({path: '/newgoods/mirrorReport/' + id})
					} else if(goodsId == 7) {
						this.$router.push({path: '/newgoods/creamReport/' + id})
					} else if(goodsId == 8) {
						console.log("baijin");
						this.$router.push({path: '/newgoods/result/' + id})
					} 
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.van-nav-bar{
		background-color: #F9FAFC;
	}
	.history{
		margin-top: 0.88rem;
		padding: 0.4rem 0.24rem;
		
		.name{
			margin-bottom: 0.3rem;
			font-size: 0.4rem;
			padding-left: 0.17rem;
			font-weight: 500;
		}
		.list{
			&>div{
				line-height: 1.75rem;
				padding: 0 0.32rem;
				margin-bottom: 0.24rem;
				box-shadow:0px 4px 8px 0px rgba(240,241,247,1);
				border-radius: 0.08rem;
				overflow: hidden;
				
				.day{
					width: 0.7rem;
					font-size: 0.2rem;
					color: #777F8F;
					line-height: 0.28rem;
					margin-top: 0.66rem;
				}
				.month{
					position: relative;
					width: 1.12rem;
					height: 1.12rem;
					margin: 0.32rem 0.16rem 0;
					padding-top: 0.1rem;
					line-height: 0.4rem;
					border-radius: 50%;
					z-index: 10;
					border: 0.06rem solid #d1d9fd;
					
					&::after{
						content: '';
						display: block;
						width: 0.02rem;
						height: 600%;
						margin-left: -0.01rem;
						position: absolute;
						top: -100%;
						left: 50%;
						z-index: -1;
						background-color: #6681FA;
					}
					dt{
						font-size: 0.4rem;
						font-weight: bold;
					}
				}
				.check{
					color: #6681FA;
					
					.van-icon{
						vertical-align: middle;
					}
				}
				.to-ques{
					.van-icon{
						vertical-align: middle;
					}
				}
				.appoint{
					margin-top: -0.58rem;
					color: #777F8F;
					line-height: 0.36rem;
					font-weight: 400;
					padding-bottom: 0.24rem;
				}
				&:first-child .month::after{
					top: 0;
				}
				.month.last::after{
					top: -600%;
				}
				.month.only::after{
					display: none;
				}
			}
		}
		.wait{
			line-height: 0.36rem;
			margin-top: -0.6rem;
			padding-bottom: 0.5rem;
			color: #777F8F;
		}
		.no-data{
			color: #777F8F;
			margin-top: 2.5rem;
			
			p{
				margin-top: 0.6rem;
				color: #9A9FB4;
			}
		}
	}
</style>